import { Component, Input, EventEmitter, Output, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';
import { distinctUntilChanged } from 'rxjs/internal/operators/distinctUntilChanged';
import * as moment from 'moment';
import { Observable } from 'rxjs/Observable';
import { ExpedientesService } from '@service/registros_admision/expediente.service';

@Component({
    selector: 'buscador-component',
    templateUrl: 'buscador.template.html',
})
export class BuscadorComponent implements OnChanges {
    @ViewChild('fecha', { static: true }) fecha: any;
    @ViewChild('texto', { static: true }) texto: any;
    public columFiltro: string;
    public columInput: string;
    public ragoFecha: string;
    @Output() buscar = new EventEmitter<{ id: any; text: any; tipo: any; valor: any; desde: any; hasta: any }>();
    public showInputSimple: boolean;
    public showInputRango: boolean;
    public dataSelect: Array<{ id: any; text: any }>;
    public dataSelected: { id: any; text: any };
    @Input() filtros: Array<{ id: any; text: any; tipo: any }>;
    @Input() selected: { id: any; text: any; tipo: any; valor: any; desde: any; hasta: any };
    @Input() tiposelect: Observable<any>;
    @Input() selectBlock: boolean = false;
    @Input() bandera: boolean = false;
    public searchText: any;
    public searchNumberInicio: any;
    public searchNumberFin: any;
    public tipoText: any;
    public tipoNumberInicio: any;
    public tipoNumberFin: any;
    public tipoFechaInicio: any;
    public tipoFechaFin: any;

    constructor(private expedientesService: ExpedientesService) {
        this.filtros = [];
        this.selected = { id: 'all', text: 'Seleccionar', tipo: '', valor: '', desde: '', hasta: '' };
        this.columFiltro = 'col-md-5';
        this.columInput = 'col-md-7';
        this.showInputSimple = false;
        this.showInputRango = false;
        this.tipoText = '';
        this.tipoNumberInicio = '';
        this.tipoNumberFin = '';
        this.tipoFechaInicio = '';
        this.tipoFechaFin = '';
        this.dataSelect = [];
        this.dataSelected = { id: '', text: '' };
    }
    ngOnChanges(change: SimpleChanges) {
        for (const propName in change) {
            if (change.hasOwnProperty(propName)) {
                switch (propName) {
                    case 'bandera': {
                        this.selectChange({
                            id: 'estado',
                            text: 'Estado',
                            tipo: 'select',
                            opciones: [
                                {
                                    id: '01',
                                    text: 'ACTIVO',
                                },
                                {
                                    id: '02',
                                    text: 'INACTIVO',
                                },
                            ],
                            valor: '',
                            desde: '',
                            hasta: '',
                        });
                        break;
                    }

                    case 'tiposelect': {
                        this.listSelect();
                        break;
                    }
                }
            }
        }
    }
    selectChange(event) {
        setTimeout(() => {
            this.handleInputFocus(event);
        }, 10);

        this.resetValues(event);

        this.selected = event;

        if (event.id === 'all' || event.id === 'ALL') {
            this.handleAll();
        } else {
            this.handleOtherCases(event);
        }
    }

    private handleInputFocus(event): void {
        switch (event.tipo) {
            case 'date':
                this.fecha.nativeElement.click();
                break;
            case 'text':
                this.texto.nativeElement.focus();
                break;
        }
    }

    private resetValues(event): void {
        event.valor = '';
        if (event.text === 'VAF') {
            this.resetVAF();
        }
        event.desde = '';
        event.hasta = '';
    }

    private handleAll(): void {
        this.showInputRango = false;
        this.buscar.emit(this.selected);
        this.columFiltro = 'col-md-5';
    }

    private handleOtherCases(event): void {
        switch (event.tipo) {
            case 'text':
                this.handleText();
                break;
            case 'number':
            case 'date':
                this.handleNumberOrDate(event);
                break;
            case 'select':
                this.handleSelect(event);
                break;
        }
    }

    private handleText(): void {
        this.tipoText = '';
        this.columFiltro = 'col-md-5';
        this.columInput = 'col-md-7';
        this.showInputRango = false;
    }

    private handleNumberOrDate(event): void {
        this.columFiltro = 'col-md-4';
        this.columInput = 'col-md-4';
        this.showInputRango = true;
        if (event.tipo === 'date') {
            this.handleDateSpecificCases(event);
        }
    }

    private handleDateSpecificCases(event): void {
        // Handle specific cases for date type if needed
    }

    private handleSelect(event): void {
        if (this.isSpecialCaseForSelect(event)) {
            this.handleSpecialCaseForSelect(event);
        } else {
            this.handleNormalCaseForSelect(event);
        }
    }

    private isSpecialCaseForSelect(event): boolean {
        return event.id === 'filtro_comite' && event.cambiar === undefined;
    }

    private handleSpecialCaseForSelect(event): void {
        this.selectChange({
            id: 'filtro_comite',
            text: 'Comite',
            cambiar: true,
            tipo: 'select',
            opciones: [
                { id: true, text: 'ACTIVO' },
                { id: false, text: 'INACTIVO' },
            ],
            valor: '',
            desde: '',
            hasta: '',
        });
    }

    private handleNormalCaseForSelect(event): void {
        if (event.id === 'tipo' || event.id === 'subtipo') {
            this.tiposelect = event.id === 'tipo' ? this.expedientesService.listarTipos() : this.expedientesService.listarSubTipos();
        }
        if (event.id === 'estado') {
            this.buscar.emit();
        }
        if (this.bandera && event.id === 'estado') {
            this.dataSelected = { id: '01', text: 'ACTIVO' };
            this.bandera = false;
        } else {
            this.dataSelected = { id: '', text: '' };
        }
        if (this.tiposelect) {
            this.listSelect();
        }
        this.columFiltro = 'col-md-5';
        this.columInput = 'col-md-7';
        this.showInputRango = false;
    }

    private resetVAF(): void {
        this.tipoNumberFin = '';
        this.tipoNumberInicio = '';
    }

    private listSelect(): void {
        this.tiposelect.subscribe(
            (response: any) => {
                this.dataSelect = response;
            },
            (error: any) => {
                // Handle error appropriately
            }
        );
    }

    dataSelectChange(event) {
        this.selected.valor = event.id;
        this.buscar.emit(this.selected);
    }

    buscarText(event) {
        Observable.create((observer) => {
            this.searchText = observer;
        })
            .pipe(distinctUntilChanged())
            .subscribe((res: string) => {
                this.selected.valor = res;
                this.buscar.emit(this.selected);
            });
        this.searchText.next(event);
    }
    numeroInicio(event) {
        if (!this.searchNumberInicio) {
            Observable.create((observer) => {
                this.searchNumberInicio = observer;
            })
                .pipe(debounceTime(300))
                .pipe(distinctUntilChanged())
                .subscribe((res: string) => {
                    this.selected.desde = Number(res.replace(/,/gi, ''));
                    if (this.selected.hasta > 0) {
                        this.buscar.emit(this.selected);
                    }
                });
        }
        this.searchNumberInicio.next(event);
    }
    numeroFin(event) {
        if (!this.searchNumberFin) {
            Observable.create((observer) => {
                this.searchNumberFin = observer;
            })
                .pipe(debounceTime(300))
                .pipe(distinctUntilChanged())
                .subscribe((res: string) => {
                    this.selected.hasta = Number(res.replace(/,/gi, ''));
                    if (this.selected.desde) {
                        this.buscar.emit(this.selected);
                    }
                });
        }
        this.searchNumberFin.next(event);
    }
    fechaInicio(event) {
        this.selected.desde = moment(event.start, 'DD/MM/YYYY').format('YYYY-MM-DD');
        this.selected.hasta = moment(event.end, 'DD/MM/YYYY').format('YYYY-MM-DD');
        this.ragoFecha = `${this.selected.desde} / ${this.selected.hasta}`;
        if (this.selected.hasta != '' && this.selected.desde != '') {
            this.buscar.emit(this.selected);
        }
    }
    fechaFin(event) {
        this.selected.hasta = moment(event).format('YYYY-MM-DD');
        if (this.selected.desde != '') {
            this.buscar.emit(this.selected);
        }
    }
}
